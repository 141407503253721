import { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { APICall } from '../utils/utils.js'


export default function Home() {

    const navigate = useNavigate()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");

    useEffect(() => {

        getIPFromAmazon()
        var token = sessionStorage.getItem('token');
        if (token && token != null) {
            navigate("/dashboard");
        }
    }, []);

    const submitHandler = (event) => {
        event.preventDefault()
        loginRequest()
    }

    function getIPFromAmazon() {
        //fetch("http://localhost/ip/").then(res => res.text()).then(data => console.log(data))
    }

     function  loginRequest() {
        
        const loginData = {
            Email: email,
            Password: password,
         };
         //navigate('/dashboard');
              
         var response = APICall(loginData, 'Authentication/Authenticate', 'POST')
             .then(resposnse => {
                 console.log('Data', resposnse);     
                 if (resposnse.StatusCode == 200) {
                     navigate('/dashboard'); 
                     sessionStorage.setItem('token', resposnse.Result.token);
                     sessionStorage.setItem('userId', resposnse.Result.userId);
                     sessionStorage.setItem('name', resposnse.Result.firstName);
                     sessionStorage.setItem('designation', resposnse.Result.designation);
                     sessionStorage.setItem('roleId', resposnse.Result.userRoles[0].roleId);
                     localStorage.setItem('token', resposnse.Result.token);
                 } else {
                     setMessage(resposnse.Message);
                     setError(resposnse.StatusCode);
                  
                 }
             //console.log('Data',resposnse);
         });
      
    }


  return (
      <>

          <div className="container-scroller">
              <div className="container-fluid page-body-wrapper full-page-wrapper">
                  <div className="content-wrapper d-flex align-items-center auth">
                      <div className="row flex-grow">
                          <div className="col-lg-4 mx-auto">
                              <div className="auth-form-light text-left p-5">
                                  <div className="brand-logo">
                                      <img src="assets/images/logo.png" />
                                  </div>
                                  <h6 className="font-weight-light">Sign in to continue....</h6>
                                  {error > 0 ?
                                      <div className="text-danger">{message ? <p>{message}</p> : null}</div>
                                      : <div className="error-blue">{message ? <p>{message}</p> : null}</div>
                                  }  
                                  <form className="pt-3" onSubmit={submitHandler}>
                                      <div className="form-group">
                                          <input type="email" className="form-control form-control-lg" id="exampleInputEmail1" placeholder="Email" name="email" onChange={(e) => setEmail(e.target.value)} required />
                                      </div>
                                      <div className="form-group">
                                          <input type="password" className="form-control form-control-lg" id="exampleInputPassword1" placeholder="Password" name="password" onChange={(e) => setPassword(e.target.value)} required />
                                      </div>
                                      <div className="mt-3">
                                          <button type="submit" className="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn">SIGN IN</button>
                                      </div>
                                      <div className="my-2 d-flex justify-content-between align-items-center">
                                          <div className="form-check">
                                              <label className="form-check-label text-muted" />

                                          </div>
                                          {/*<a href="#" className="auth-link text-black">Forgot password?</a> */}
                                      </div>

                                  </form>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>

          

      </>
  )
}